export class AriaLive {
    private static _liveElement: HTMLElement;

    public static get liveElement(): HTMLElement {
        if (AriaLive._liveElement) return AriaLive._liveElement;
        document.body.insertAdjacentHTML(
            "beforeend",
            `<div id="AriaLiveService" aria-live="polite" role="region" style="opacity: 0; position: absolute"></div>`,
        );
        AriaLive._liveElement = document.body.querySelector("#AriaLiveService");
        return AriaLive._liveElement;
    }

    public static announce(announcement: string, assertive?: boolean) {
        AriaLive.liveElement.setAttribute("aria-live", assertive ? "assertive" : "polite");
        AriaLive.liveElement.innerText = announcement;
    }
}
