import { Item } from "./Item";
import { LooseAutocomplete, ValueOf } from "./Util";
import { S25WsNode } from "./S25WsNode";
import { Proto } from "./Proto";
import CharBoolean = Proto.CharBoolean;
import NumericalString = Proto.NumericalString;
import LowerStringBoolean = Proto.LowerStringBoolean;

export namespace Report {
    export interface Data {
        subject: "Report";
        id: Item.Ids.Report;
    }

    export interface SimpleObject {
        rpt_name: string;
        rpt_use: Use;
        rpt_id: number;
        rpt_engine: Engine;
        object_type: ObjectType;
        document_id?: number;
    }

    export type Object = S25WsNode & {
        favorite: CharBoolean;
        href: string;
        last_mod_dt: string;
        last_mod_user: string;
        object_type: ObjectType;
        report_engine: Engine;
        report_filename: string;
        report_group_id: NumericalString | number;
        report_id: NumericalString | number;
        report_name: string;
        document_id?: number;
        content_disposition?: string;
        itemId?: number | NumericalString;
        itemName?: string;
    };

    export type Meta = S25WsNode & {
        async: LowerStringBoolean;
        content_disposition: Object["content_disposition"];
        mime_type: string;
        report_run: Run[] & { [key: string]: any };
        pipeline: LooseAutocomplete<"PDF">;
        favorite: Object["favorite"];
        object_type: Object["object_type"];
        report_engine: Object["report_engine"];
        report_filename: Object["report_filename"];
        report_group_id: Object["report_group_id"];
        report_id: Object["report_id"];
        report_name: Object["report_name"];
        parameter?: Parameter[];
        rpt_name?: Object["report_name"];
        document_id?: Object["document_id"];
    };

    export type Run = S25WsNode & {
        false_value: string;
        true_value: string;
        report_run_id: NumericalString;
        rpt_run_name: string;
    };

    export type Context = {
        num_parm1?: any;
        num_parm2?: NumericalString;
        num_parm3?: NumericalString;
        char_parm4?: any;

        [key: string]: any;
    };

    export type Parameter = S25WsNode & {
        parameter_field_mapping: string;
        parameter_name: string;
        parameter_number: string;
        parameter_type: string;
        required: CharBoolean;
        sort_order: NumericalString;
    };

    export type Engine = "JR" | "WS" | "DM";

    export const Use = {
        None: 0,
        Confirmation: 1,
        Invoice: 2,
        Payment: 4,
    } as const;
    export type Use = ValueOf<typeof Use>;

    export const ObjectType = {
        NonSpecific: 0,
        Event: 1,
        Organization: 2,
        Contact: 3,
        Space: 4,
        Resource: 6,
        EventDocument: 10,
        PaymentDocument: 11,
        ReservationDocument: 12,
        OrganizationDocument: 13,
        EventListingDocument: 14,
        ReservationListingDocument: 15,
        LocationListingDocument: 16,
        ResourceListingDocument: 17,
        OrganizationListingDocument: 18,
    } as const;
    export type ObjectType = ValueOf<typeof ObjectType>;

    export const Reports = {
        LocationDailySheet: { id: -147, object_type: ObjectType.Space },
        InvoiceRelated: { id: -149, object_type: ObjectType.Event },
        EventShell: { id: -198, object_type: ObjectType.Event },
        DefaultInvoice: { id: -199, object_type: ObjectType.Event },
        ReservationShell: { id: -210, object_type: ObjectType.ReservationDocument },
        OrganizationShell: { id: -217, object_type: ObjectType.OrganizationDocument },
        PaymentShell: { id: -236, object_type: ObjectType.PaymentDocument },
        EventListingShell: { id: -228, object_type: ObjectType.EventListingDocument },
        ReservationListingShell: { id: -229, object_type: ObjectType.ReservationListingDocument },
        LocationListingShell: { id: -230, object_type: ObjectType.LocationListingDocument },
        ResourceListingShell: { id: -231, object_type: ObjectType.ReservationListingDocument },
        OrganizationListingShell: { id: -232, object_type: ObjectType.OrganizationDocument },
    } as const;

    export const reportsById = new Map(Object.values(Reports).map((report) => [report.id, report]));
}
