//@author: mandy
import { S25Util } from "../util/s25-util";
import { jSith } from "../util/jquery-replacement";

declare global {
    interface Window {}
}

export class AlertService {
    public static promises: any = [];

    public static alert(alertStr: any) {
        //TODO: once s25ModalService is migrated switch this back
        let s25ModalService: any = window.angBridge.$injector.get("s25ModalService");
        let defer = jSith.defer(); //create defer

        //get local reference to any alert promises that have been called
        //if this is the first call to alert, then it is empty array
        //if two calls come in at the same time, one will take precedence due to JS's single threaded nature
        //and the second call will see the first one's promise in this array now -- therefore, the second call
        //will wait for the first one to finish
        let waitForPromises = [].concat(AlertService.promises);

        //add our defer promise (our current call's promise) to the gloabl list
        //so any subsequent calls will wait on us now
        AlertService.promises.push(defer.promise);

        //wait for any alerts called before us to finish
        return S25Util.all(waitForPromises).then(() => {
            //call our modal
            s25ModalService.modal("alert", s25ModalService.dialogType("OK", { title: "Alert", msg: alertStr })).then(
                () => {
                    defer.resolve(); //resolve our promise when the modal is closed; this allows the next alert, if any, to show now
                },
                () => {
                    defer.resolve(); //ditto if there's some error for some reason
                },
            );
        });
    }

    public static confirm(message: string): Promise<boolean> {
        let s25ModalService: any = window.angBridge.$injector.get("s25ModalService");
        let defer = jSith.defer();
        let modalData = s25ModalService.dialogType("No Yes", { answer: 0, title: "Confirm", msg: message });
        s25ModalService.closeCurrentModalAndOpenModal("dialog", modalData).then(
            () => {
                defer.resolve(modalData.answer > 0);
            },
            () => {
                defer.resolve(modalData.answer > 0);
            },
        );
        return defer.promise;
    }
}

window.alert = AlertService.alert;
