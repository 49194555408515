//@author devin
import { DataAccess } from "../dataaccess/data.access";

export class AuditService {
    public static getAudits(params: any) {
        //elements injected by caller
        var url = "/auditevent/list.json?itemid=" + params.itemId + "&itemTypeId=" + params.itemTypeId;

        //elements injected by List
        url += params.modelBean && params.modelBean.itemsPerPage ? "&page_size=" + params.modelBean.itemsPerPage : "";
        url += params.modelBean && params.modelBean.chosen.page ? "&page=" + params.modelBean.chosen.page : "";
        url += params.modelBean && params.modelBean.cacheId ? "&paginate=" + params.modelBean.cacheId : "&paginate";
        url += params.modelBean && params.modelBean.sortCol.sort ? "&sort=" + params.modelBean.sortCol.sort : "";
        url += params.modelBean && params.modelBean.sortCol.order ? "&order=" + params.modelBean.sortCol.order : "";

        return DataAccess.get(DataAccess.injectCaller(url, "AuditDao.getAudits"));
    }
}
