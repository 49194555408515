// Util for generic actions dealing with accl microservices
import { S25Util } from "../../util/s25-util";
import { S25WsMicroService } from "./S25WsMicroService";
import Content = S25WsMicroService.Content;

export class MicroUtil {
    public static wrapPayloadItem<T>(item: T): Content<T> {
        return {
            content: {
                apiVersion: "0.1",
                data: {
                    items: [item],
                },
            },
        };
    }

    public static getMicroItem<T>(wrapper: Content<T>) {
        return this.getMicroItems(wrapper)[0];
    }

    public static getMicroItems<T>(wrapper: Content<T>) {
        return S25Util.array.forceArray(wrapper?.content?.data?.items);
    }
}
