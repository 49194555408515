import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Security } from "../pojo/Security";
import accessItem = Security.accessItem;
import apItem = Security.apItem;
import olsItem = Security.olsItem;
import apRights = Security.apRights;

const type2APService: any = {
    4: {
        endPoint: "space_assign.json",
        idName: "space_id",
        apName: "space_assignment_policy",
        kind: "space",
    },
    6: {
        endPoint: "resource_assign.json",
        idName: "resource_id",
        apName: "resource_assignment_policy",
        kind: "resource",
    },
};

export class AssignPolicyService {
    public static getForCurrentUser(itemTypeId: number, itemIds: number[], includeOls: boolean = false) {
        let itemTypeName = type2APService[itemTypeId].kind;

        let url = `/micro/${itemTypeName}/access/currentUser/list.json?include=assign_policy`;
        url = url.concat(includeOls ? "+ols" : "");
        url = url.concat(`&${type2APService[itemTypeId].idName}=${itemIds.join("+")}`);
        return DataAccess.get(DataAccess.injectCaller(url, "AssignPolicyService.getForCurrentUser")).then((resp) => {
            return resp?.content?.data?.items || [];
        });
    }
    public static getNew(itemTypeId: number, itemIds: number[], includeOls: boolean = false): Promise<accessItem[]> {
        let itemTypeName = type2APService[itemTypeId].kind;

        let url = `/micro/${itemTypeName}/${itemIds.join("+")}/access/detail.json?include=assign_policy`;
        url = url.concat(includeOls ? "+ols" : "");
        return DataAccess.get(DataAccess.injectCaller(url, "AssignPolicyService.getNew")).then((resp) => {
            return resp?.content?.data?.items || [];
        });
    }

    public static setNew(
        itemTypeId: number,
        itemId: number,
        apItem: apItem[],
        olsItem?: olsItem[],
    ): Promise<accessItem[]> {
        let itemTypeName = type2APService[itemTypeId].kind;
        let payload: any = {
            content: {
                data: {
                    items: [
                        {
                            kind: itemTypeName,
                            id: itemId,
                            assignPolicy: S25Util.array.forceArray(apItem),
                            OLS: olsItem || undefined,
                        },
                    ],
                },
            },
        };

        // let url = "/micro/" + itemTypeName + "/" + itemId + "/access/detail.json";
        let url = "/micro/" + itemTypeName + "/access/list.json";
        return DataAccess.put(DataAccess.injectCaller(url, "AssignPolicyService.setNew"), payload).then((resp) => {
            return resp?.content?.data?.items || [];
        });
    }

    public static copyNew(itemTypeId: number, srcItemId: number, destItemId: number, includeOls: boolean) {
        return AssignPolicyService.getNew(itemTypeId, [srcItemId], includeOls).then((resp) => {
            const origResp = S25Util.deepCopy(resp[0]);
            if (resp[0].OLS) {
                for (let ols of resp[0].OLS) {
                    convertToNewOLS(ols, itemTypeId);
                }
            }
            if (resp[0].assignPolicy) {
                for (let ap of resp[0].assignPolicy) {
                    convertToNewAp(ap);
                }
            }
            return AssignPolicyService.setNew(itemTypeId, destItemId, resp[0].assignPolicy, resp[0].OLS).then(
                (results) => {
                    return AssignPolicyService.compareObjectAccess(origResp, results[0], itemTypeId);
                },
            );
        });
    }

    public static copy(itemTypeId: number, srcItemId: number, destItemId: number, includeOls = true) {
        return AssignPolicyService.copyNew(itemTypeId, srcItemId, destItemId, includeOls);
    }

    /**
     * Compares the AP and OLS for the two items provided.
     * @param obj1
     * @param obj2
     * @param itemTypeId
     * @param exitEarly - exit as soon as the first difference is found
     *
     */
    public static compareObjectAccess(obj1: accessItem, obj2: accessItem, itemTypeId: number, exitEarly = false) {
        let isEqual = true;
        let differences: any = {};

        //if ols is only passed for one object ignore it
        if (obj1.OLS && obj2.OLS) {
            for (let i = 0; i < obj1.OLS.length; i++) {
                if (!AssignPolicyService.compareOLS(obj1.OLS[i], obj2.OLS[i], itemTypeId)) {
                    isEqual = false;
                    Object.assign(differences, { [obj1.OLS[i].groupId]: { obj1: obj1.OLS[i], obj2: obj2.OLS[i] } });
                    if (exitEarly) i = obj1.OLS.length;
                }
            }
        }

        if (isEqual && obj1.assignPolicy && obj2.assignPolicy) {
            for (let i = 0; i < obj1.assignPolicy.length; i++) {
                if (!AssignPolicyService.compareAp(obj1.assignPolicy[i], obj2.assignPolicy[i])) {
                    isEqual = false;
                    Object.assign(differences, {
                        [obj1.assignPolicy[i].groupId]: { obj1: obj1.assignPolicy[i], obj2: obj2.assignPolicy[i] },
                    });
                    if (exitEarly) i = obj1.assignPolicy.length;
                }
            }
        }

        return { isEqual: isEqual, differences: differences };
    }

    public static compareAp(obj1: apItem, obj2: apItem) {
        let isEqual = true;
        convertToNewAp(obj1);
        convertToNewAp(obj2);
        //compare strings first since its is faster
        if (S25Util.stringify(obj1) !== S25Util.stringify(obj2)) {
            isEqual =
                considerPermsEqual(obj1.assignPerm, obj2.assignPerm) &&
                considerPermsEqual(obj1.unassignPerm, obj2.unassignPerm);
            //     consierPermsEqual(obj1.assignPermOLS, obj2.assignPermOLS); // Ignore assignPermOLS now since it only shows for legacy perms
        }
        return isEqual;
    }

    public static compareOLS(obj1: olsItem, obj2: olsItem, itemTypeId: number) {
        let isEqual = true;
        delete obj1.security;
        delete obj2.security;
        convertToNewOLS(obj1, itemTypeId);
        if (S25Util.stringify(obj1) !== S25Util.stringify(obj2)) {
            isEqual = considerPermsEqual(obj1.edit, obj2.edit);
        }
        return isEqual;
    }
}

/**
 * in place converts an AP item from old format into new, converting "F" to "unassignApprove" etc.
 * @param ap
 */
function convertToNewAp(ap: apItem): void {
    //required fields for the ap service
    ap.assignable = !!ap.assignable;
    ap.assignPerm = S25Util.isUndefined(ap.assignPerm) ? "notSet" : ap.assignPerm;
    ap.unassignPerm = S25Util.isUndefined(ap.unassignPerm) ? "notSet" : ap.unassignPerm;

    //New tables do not have a value for Assign, but old does have a value
    switch (ap.assignPerm) {
        case "N":
            ap.assignPerm = "request";
            ap.unassignPerm = "request";
            break;
        case "R":
            ap.assignPerm = "request";
            ap.unassignPerm = "unassign";
            break;
        case "C":
            ap.assignPerm = "assign";
            ap.unassignPerm = "unassign";
            break;
        case "F":
            ap.assignPerm = "assignApprove";
            ap.unassignPerm = "unassignApprove";
            break;
    }

    if (ap.exceptionDays && ap.exceptionDays.length > 0) {
        for (let exception of ap.exceptionDays) {
            exception.startDt =
                exception.startDt && S25Util.date.toS25ISODateStr(S25Util.date.dropTZString(exception.startDt));
            exception.endDt =
                exception.endDt && S25Util.date.toS25ISODateStr(S25Util.date.dropTZString(exception.endDt));
        }
    }
}

/**
 * in place converts an OLS item from old/default format into new.
 * remove schedule right, notSet goes to "N"
 * @param ols
 * @param itemTypeId
 */
function convertToNewOLS(ols: olsItem, itemTypeId: number): void {
    delete ols.security; //unclear what security is used for
    //might as well set schedule perm even though it is retired.
    if ([4, 6].includes(itemTypeId)) {
        ols.schedule = "F";
    } else {
        delete ols.schedule;
    }
    if (!["F", "C", "R", "N"].includes(ols.edit)) ols.edit = "N"; //"notSet" => "N" - no default or explicit ols was provided
}

/**
 *
 * @param val1
 * @param val2
 */
function considerPermsEqual(val1: apRights, val2: apRights) {
    return val1 === val2 || val1 === "notSet" || val2 === "notSet";
}
